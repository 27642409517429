import React from "react"
import { Helmet } from "react-helmet"

export const getCustomStyles = settings => {
  return (
    <>
      {settings?.customStyles && (
        <Helmet>
          <style>{settings?.customStyles}</style>
        </Helmet>
      )}
      {settings?.rbeaStyles && (
        <Helmet>
          <style>{settings?.rbeaStyles}</style>
        </Helmet>
      )}
    </>
  )
}
